<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!is_viewable && !loading" justify="center">
      <v-col xs="12" xl="6">
        <v-alert type="warning">
          This form is not currently available for viewing.
        </v-alert>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="this.is_viewable && !loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">Welcome to our KYC Homepage!</p>

            <p class="grey--text">
              We're here to ensure the security and compliance of our platform
              for all users. To maintain the highest standards of trust and
              transparency, we kindly request that you complete the initial KYC
              (Know Your Customer) verification process before proceeding
              further.
            </p>

            <!-- initial -->
            <v-row>
              <v-col>
                <v-card @click="handleInitialKYC" class="mb-6">
                  <v-card-title>
                    <v-icon> mdi-rocket-launch-outline </v-icon>
                    <div class="ml-2">Initial</div>

                    <v-spacer />

                    <div v-if="!initial_data" class="red--text">Incomplete</div>
                    <v-icon v-if="!initial_data" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="initial_data" class="green--text">Completed</div>
                    <v-icon v-if="initial_data" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p class="grey--text">
                  After completing the Initial KYC, we request additional
                  information based on the accounts you have provided.
                </p>
              </v-col>
            </v-row>

            <v-divider class="mb-5" v-if="initial_data" />

            <!-- personal -->
            <v-row v-if="this.is_personal">
              <v-col>
                <p class="text-body-2 grey--text">
                  Since you are investing in mortgages using personal funds
                  (e.g., RRSP, TFSA, Cash). Please complete the personal KYC
                  form below.
                </p>

                <v-card @click="handlePersonalKYC" class="mb-6">
                  <v-card-title>
                    <v-icon> mdi-account-details </v-icon>
                    <div class="ml-2">Personal</div>

                    <v-spacer />

                    <div v-if="!personal_data" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!personal_data" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="personal_data" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="personal_data" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <!-- corporation -->
            <v-row v-if="this.is_corporation">
              <v-col>
                <p class="text-body-2 grey--text">
                  Since you are investing in mortgages with funds from a
                  corporation you own. Please complete the following KYC on each
                  corporation listed below .
                </p>

                <v-card
                  v-for="account in private_corporation_accounts"
                  :key="account.id"
                  @click="
                    !account.frm_rsp
                      ? handleCorporationKYC(account.id)
                      : handleCorporationKYC()
                  "
                  class="mb-6"
                >
                  <v-card-title>
                    <v-icon> mdi-office-building </v-icon>
                    <div class="ml-2">{{ account.name }}</div>

                    <v-spacer />

                    <div v-if="!account.frm_rsp" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!account.frm_rsp" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="account.frm_rsp" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="account.frm_rsp" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <!-- mortgage company -->
            <v-row v-if="this.is_mortgage_company">
              <v-col>
                <p class="text-body-2 grey--text">
                  You choose to be investing in mortgages on behalf of a
                  corporation that is primarily in the business of lending money
                  via mortgages (e.g., Mortgage Investment Company, Mortgage
                  Brokerage). Please complete the company KYC form below.
                </p>

                <v-card
                  v-for="account in mortgage_company_accounts"
                  :key="account.id"
                  @click="
                    !account.frm_rsp
                      ? handleMortgageCompanyKYC(account.id)
                      : handleMortgageCompanyKYC()
                  "
                  class="mb-6"
                >
                  <v-card-title>
                    <v-icon> mdi-domain </v-icon>
                    <div class="ml-2">{{ account.name }}</div>

                    <v-spacer />

                    <div v-if="!account.frm_rsp" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!account.frm_rsp" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>
                    <div v-if="account.frm_rsp" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="account.frm_rsp" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.push({ path: '/' })"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "InitialKYC",

  data: () => ({
    errorMessage: "",

    loading: false,
    error: false,
    is_viewable: false,

    is_personal: false,
    is_corporation: false,
    is_mortgage_company: false,

    corporation: null,
    initial_data: null,
    mortgage_company: null,
    personal_data: null,

    company_id: null,
    user_id: null,

    companyData: null,
  }),

  computed: {
    private_corporation_accounts() {
      return this.companyData.accounts.filter(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
    },

    mortgage_company_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category !== "Private"
      );
    },
  },

  methods: {
    handleInitialKYC() {
      if (!this.initial_data) {
        this.$router.push({
          path: `/form_link/initial-kyc-2024-v1/company/${this.company_id}/read?user_id=${this.user_id}&token=${this.$route.query.user_token}`,
        });
      } else {
        console.log("initial data was already submitted! ", this.initial_data);
      }
    },

    handlePersonalKYC() {
      if (!this.personal_data) {
        this.$router.push({
          path: `/form_link/personal-kyc-2024-v1/company/${this.company_id}/read?user_id=${this.user_id}&token=${this.$route.query.user_token}`,
        });
      } else {
        console.log(
          "personal data was already submitted! ",
          this.personal_data
        );
      }
    },

    handleCorporationKYC(account_id) {
      if (account_id) {
        this.$router.push({
          path: `/form_link/corporation-kyc-2023-v1/company/${this.company_id}/read?user_id=${this.user_id}&account_id=${account_id}&token=${this.$route.query.user_token}`,
        });
      }

      if (!account_id) {
        console.log("corporation data was already submitted! ");
      }
    },

    handleMortgageCompanyKYC(account_id) {
      if (account_id) {
        this.$router.push({
          path: `/form_link/mortgage-company-kyc-2023-v1/company/${this.company_id}/read?user_id=${this.user_id}&account_id=${account_id}&token=${this.$route.query.user_token}`,
        });
      }

      if (!account_id) {
        console.log(
          "mortgage company data was already submitted! ",
          this.mortgage_company_data
        );
      }
    },

    will_check_initial_response() {
      // find the section
      const section = this.initial_data.frm.frm_sects.find(
        (sect) => sect.title === "Investment Information"
      );

      // check if it is personal
      this.is_personal = section.frm_rsp_qs.some(
        (q) =>
          (q.frm_q.title ===
            "Are you investing in mortgages using personal funds (e.g., RRSP, TFSA, Cash)?" &&
            q.response === "Yes") ||
          (q.frm_q.title ===
            "Are you investing in mortgages with funds from a corporation you own?" &&
            q.response === "Yes")
      );

      if (this.is_personal) console.log("Includes Personal KYC");

      // check if it is corporation
      this.is_corporation = section.frm_rsp_qs.some(
        (q) =>
          q.frm_q.title ===
            "Are you investing in mortgages with funds from a corporation you own?" &&
          q.response === "Yes"
      );

      if (this.is_corporation) console.log("Includes Corporation KYC");

      // check if it is mortgage company
      this.is_mortgage_company = section.frm_rsp_qs.some(
        (q) =>
          q.frm_q.title ===
            "Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?" &&
          q.response === "Yes"
      );

      if (this.is_mortgage_company)
        console.log("Includes Mortgage Company KYC");
    },

    async handleBtnFinish() {
      try {
        this.is_submitting = true;

        const res = await API().patch(
          `/api/form-access-link/update_company_kyc_status`,
          {
            company_id: this.company_id,
            kyc_form_is_submitted: true,
            token: this.$route.query.user_token,
          }
        );

        if (res.status === 201) {
          console.log("Investor KYC was submitted. ", res.data);

          this.is_submitted = true;

          setTimeout(() => {
            this.is_submitted = false;
          }, 2000);

          setTimeout(() => {
            this.$router.push({
              path: `/`,
            });
          }, 3000);
        } else {
          console.log("Investor KYC was not submitted.");
        }

        this.is_submitting = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        this.errorMessage = "Submitting KYC " + error.message;
        setTimeout(() => {
          this.pageIsError = false;
          this.errorMessage = "";
        }, 2000);
      }
    },

    async will_analyze_token() {
      try {
        this.loading = true;

        const res = await API().get(
          `api/form-access-link/read_kyc_homepage_data?token=${this.$route.query.user_token}`
        );

        this.company_id = res.data.id;
        this.user_id = res.data.users[0].id;

        if (res.status === 200) {
          console.log("res.data: ", res.data);
          this.companyData = res.data;

          // check if initial info was submitted
          const initial_kyc = res.data.frm_rsps.find(
            (frm_rsp) => frm_rsp.frm.unique_id === "initial-kyc-2024-v1"
          );

          // get initial data
          if (initial_kyc) {
            const found_initial_data = await API().get(
              `api/form-access-link/read_form_response?id=${initial_kyc.id}`
            );

            console.log("found_initial_data: ", found_initial_data);

            this.initial_data = found_initial_data.data;

            this.will_check_initial_response();
          }

          // check if personal info was submitted
          const personal_kyc = res.data.frm_rsps.find(
            (frm_rsp) => frm_rsp.frm.unique_id === "personal-kyc-2024-v1"
          );

          // get personal data
          if (personal_kyc) {
            const found_personal_data = await API().get(
              `api/form-access-link/read_form_response?id=${personal_kyc.id}`
            );

            console.log("personal Data: ", found_personal_data);
            this.personal_data = found_personal_data.data;
          }

          this.loading = false;
          this.is_viewable = true;
        }

        if (res.status === 204) {
          console.log("record for this tokenasdasd does not exist.");
          this.is_viewable = false;
          this.loading = false;
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Getting access link token data: " + error.message;
        console.log("Getting access link token data: " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
          this.is_viewable = false;
          this.loading = false;
        }, 3000);
      }
    },
  },

  mounted() {
    console.log("token: ", this.$route.query.user_token);
    this.will_analyze_token();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
